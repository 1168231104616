//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration';
import ColorSchemeToggle from '@saulhardman/vue-color-scheme-toggle';

import { trackGoal, TOGGLE_COLOR_SCHEME } from '~/assets/js/analytics';

export default {
  components: {
    LazyHydrate,
    ColorSchemeToggle,
  },

  data() {
    return {
      links: [
        {
          href: 'https://codepen.io/saulhardman',
          label: 'CodePen',
          title: "See some of Saul's visual experiments",
        },

        {
          href: 'https://twitter.com/saulhardman',
          label: 'Twitter',
          title: 'Follow Saul on Twitter',
        },

        {
          href: 'https://dev.to/saul',
          label: 'DEV',
          title: 'Follow Saul on the DEV community',
        },
      ],
    };
  },

  methods: {
    handleColorSchemeToggleClick() {
      trackGoal(TOGGLE_COLOR_SCHEME);
    },
  },
};
