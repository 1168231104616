//
//
//
//
//
//
//
//
//
//
//
//
//
//

const UNDERLINED = 'underlined';
const PRIMARY = 'primary';
const SECONDARY = 'secondary';

const TYPES = {
  UNDERLINED,
  PRIMARY,
  SECONDARY,
};

export default {
  props: {
    href: {
      type: String,
    },

    to: {
      type: [String, Object],
    },

    types: {
      type: Array,

      validator: (types) =>
        types.every((type) => Object.values(TYPES).includes(type)),

      default: () => [],
    },
  },

  computed: {
    className() {
      return [
        'c-action-link',

        ...[
          'c-action-link--underlined',
          'c-action-link--primary',
          'c-action-link--secondary',
        ].filter((className) =>
          this.types.some((type) => className.endsWith(type)),
        ),
      ];
    },
  },
};
