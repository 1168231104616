//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration';
import ColorSchemeToggle from '@saulhardman/vue-color-scheme-toggle';

import SiteLogo from '~/assets/img/logo.svg';
import { trackGoal, TOGGLE_COLOR_SCHEME } from '~/assets/js/analytics';

export default {
  components: {
    LazyHydrate,
    ColorSchemeToggle,
    SiteLogo,
  },

  data() {
    return {
      logoSize: 96,
    };
  },

  computed: {
    isHomepage() {
      return this.$route.path === '/';
    },
  },

  methods: {
    handleColorSchemeToggleClick() {
      trackGoal(TOGGLE_COLOR_SCHEME);
    },
  },
};
