var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:"\n    c-link-list\n    s-link-list\n    list-none\n    delta:flex\n    delta:items-center\n    m-0\n    ml-auto\n    p-0\n    w-auto\n    font-medium\n    text-right\n  "},[_vm._t("before"),_vm._v(" "),_vm._l((_vm.links),function(ref,index){
  var to = ref.to;
  var href = ref.href;
  var label = ref.label;
  var title = ref.title;
return _c('li',{key:index},[_c(_vm.isExternal(href) || !_vm.isCurrentPage(to) ? 'action-link' : 'span',_vm._b({tag:"component",staticClass:"c-link-list__link inline-block p-2",class:{
        'c-link-list__link--external': _vm.isExternal(href),
      }},'component',
        _vm.isExternal(href) || !_vm.isCurrentPage(to)
          ? { to: to, href: href, title: title, types: ['primary'] }
          : {}
      ,false),[_vm._v("\n      "+_vm._s(label)+"\n    ")])],1)}),_vm._v(" "),_vm._t("after")],2)}
var staticRenderFns = []

export { render, staticRenderFns }