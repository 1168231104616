//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActionLink from '~/components/ActionLink.vue';

export default {
  components: {
    ActionLink,
  },

  props: {
    links: {
      type: Array,

      required: true,
    },
  },

  methods: {
    isExternal(href = '') {
      return href.startsWith('https://');
    },

    isCurrentPage(to) {
      return this.$route.path === to;
    },
  },
};
