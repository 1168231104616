//
//
//
//
//
//
//
//
//
//

import { SITE_TITLE } from '~/assets/js/constants';

export default {
  props: {
    error: {
      type: Object,

      required: true,
    },
  },

  computed: {
    title() {
      const { statusCode } = this.error;

      if (statusCode === 404) {
        return 'Page Not Found';
      }

      return 'An Error Occurred';
    },
  },

  head() {
    return {
      title: `${this.title} | ${SITE_TITLE}`,
    };
  },
};
