const isProduction = () => {
  return (
    process.env.SITE_ENV === 'production' &&
    window.location.hostname === process.env.PRODUCTION_HOSTNAME
  );
};

export const TOGGLE_COLOR_SCHEME = '8CG1GCUW';
export const COPY_EMAIL_ADDRESS = 'MIW0SJHA';

const GOALS = {
  TOGGLE_COLOR_SCHEME,
  COPY_EMAIL_ADDRESS,
};

const getGoalNameFromId = (value) => {
  return Object.keys(GOALS).find((key) => GOALS[key] === value);
};

export const trackGoal = (goalId) => {
  const { fathom, Sentry } = window;

  if (!isProduction()) {
    const goal = getGoalNameFromId(goalId);

    console.info('Fathom analytics track goal: ', { goal });

    return;
  }

  if (typeof fathom === 'undefined') {
    const message = 'Fathom missing whilst attempting to track goal.';

    if (typeof Sentry !== 'undefined') {
      Sentry.captureMessage(message);
    }

    console.warn(message);

    return;
  }

  fathom.trackGoal(goalId, 0);
};

export const trackPageview = (url) => {
  const { fathom, Sentry } = window;

  if (!isProduction()) {
    console.info('Fathom analytics track pageview: ', { url });

    return;
  }

  if (typeof fathom === 'undefined') {
    const message = 'Fathom missing whilst attempting to track pageview.';

    if (typeof Sentry !== 'undefined') {
      Sentry.captureMessage(message);
    }

    console.warn(message);

    return;
  }

  fathom.trackPageview({ url });
};
