//
//
//
//
//
//
//
//
//
//
//
//
//

import CopyToClipboardIcon from '~/assets/img/icons/icon-copy-to-clipboard.svg';
import DoneIcon from '~/assets/img/icons/icon-done.svg';
import { trackGoal, COPY_EMAIL_ADDRESS } from '~/assets/js/analytics';

export default {
  components: {
    CopyToClipboardIcon,
    DoneIcon,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: String,

      required: true,
    },
  },

  data() {
    return {
      isDone: false,
    };
  },

  computed: {
    componentName() {
      if (this.isDone) {
        return 'done-icon';
      }

      return 'copy-to-clipboard-icon';
    },
  },

  methods: {
    handleClick() {
      if (this.isDone) {
        return;
      }

      trackGoal(COPY_EMAIL_ADDRESS);

      this.writeText();
    },

    async writeText() {
      await navigator.clipboard.writeText(this.value);

      this.isDone = true;

      setTimeout(() => {
        this.isDone = false;
      }, 2000);
    },
  },
};
