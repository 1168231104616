//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { curriculumVitaeUrl } from '~/assets/js/urls';

export default {
  data() {
    return {
      links: [
        {
          to: curriculumVitaeUrl(),
          title: "View Saul's Curriculum Vitae",
          label: 'Experience',
        },

        {
          href: 'https://viewsource.io/',
          label: 'Writing',
          title: 'Right Click → View Page Source',
        },

        {
          href: 'https://github.com/saulhardman/',
          label: 'GitHub',
          title: 'Follow Saul on GitHub',
        },
      ],
    };
  },
};
