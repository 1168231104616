import { render, staticRenderFns } from "./SiteHeader.vue?vue&type=template&id=17c8dc05&"
import script from "./SiteHeader.vue?vue&type=script&lang=js&"
export * from "./SiteHeader.vue?vue&type=script&lang=js&"
import style0 from "./SiteHeader.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ActionLink: require('/Users/saulhardman/code/saulhardman.com/site/components/ActionLink.vue').default,SiteNavigation: require('/Users/saulhardman/code/saulhardman.com/site/components/SiteNavigation.vue').default})
